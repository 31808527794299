import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Bimetal = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						pH標準液　安全データシート
						<br class="sp" />
						（PDFファイル）
					</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
						<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6401-00-sds.pdf" target={"_blank"} rel="noreferrer">
									No.6401-00　フタル酸塩標準液 pH4.01(JCSS)
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6401-10-sds.pdf" target={"_blank"} rel="noreferrer">
									No.6401-10　フタル酸塩標準液 pH4.01
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6402-00-sds.pdf" target={"_blank"} rel="noreferrer">
								    No.6402-00　中性リン酸塩標準液 pH6.86(JCSS)
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6402-10-sds.pdf" target={"_blank"} rel="noreferrer">
								    No.6402-10　中性リン酸塩標準液 pH6.86
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6403-00-sds.pdf" target={"_blank"} rel="noreferrer">
								    No.6403-00　炭酸塩標準液 pH10.01(JCSS)
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6403-10-sds.pdf" target={"_blank"} rel="noreferrer">
								    No.6403-10　炭酸塩標準液 pH10.01
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6404-00-sds.pdf" target={"_blank"} rel="noreferrer">
								    No.6404-00　ほう酸塩標準液 pH9.18(JCSS)
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/ph-sds/6404-10-sds.pdf" target={"_blank"} rel="noreferrer">
								    No.6404-10　ほう酸塩標準液 pH9.18
								</a>
							</li>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Bimetal;
